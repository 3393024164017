@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/variables.scss';
@import '../../styles/base/base.scss';

.branch {
    margin-top: -80px;
    width: 25%;

    @include respond(lg) {
        margin-top: -50px;
        // background-color: black;
    }

    @include respond(sm) {
        width: 40%;
    }
}

.stats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95vh;
    margin: -3rem 0rem 3rem 0rem;
    // margin-bottom: 10rem;

    @include respond(sm) {
        height: 65vh;
    }
    
    @include respond(xs) {
        height: 55vh;
    }

    h1 {
        font-size: 3.4rem;
        color: $dark-orange;
        // letter-spacing: 0.5rem;
        word-spacing: 1rem;
        margin-bottom: 3rem;
        text-align: center;
    }

    img {
        max-height: 70vh;
        width: 100%;
    }
}