@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/variables.scss';
@import '../../styles/base/base.scss';

.about {
  background-color: $lighter-green;
  color: $dark-green;
  padding: 10rem 5rem;

  @include respond(sm) {
    padding: 10rem 0rem;
  }

  h1 {
    font-size: 5rem;
    margin-bottom: 3rem;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8rem;

    @include respond(lg) {
      grid-template-columns: 1fr 0.5fr;
    }

    @include respond(md) {
      grid-template-columns: 1fr;
    }

    p {
      font-size: 2.4rem;
      text-align: justify;

      @include respond(md) {
        padding-right: 0rem;
      }

      a {
        text-decoration: none;
        color: $dark-green;
      }
    }

    .image {
      display: flex;

      @include respond(md) {
        justify-content: center;
      }

      // justify-content: right;
      // align-items: center;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 15px;

        @include respond(md) {
          width: 80%;
        }
      }
    }
  }
}
