*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; // 1rem = 10px    10px/16px = 62.5%

  @include respond(lg) {
    //width < 1200?
    font-size: 56.25%; // 1rem = 9px, 9/16 = 56.25%
  }

  @include respond(md) {
    //width < 900?
    font-size: 50%; // 1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px, 12/16 = 75%
  }

  overflow-x: hidden;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: $background;
}
