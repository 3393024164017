@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/variables.scss';
@import '../../styles/base/base.scss';

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  
  ul {
    width: 500px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    @include respond(md) {
      width: 400px;
    }

    li {
      list-style: none;
      padding: 0px 10px;
    }

    a {
      font-size: 1.7rem;
      font-weight: 1000;
      text-decoration: none;
      color: $dark-green;
      transition: all 0.5s ease 0s;
    }

    a:hover {
      color: $orange;
    }
  }
}
