@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/variables.scss';
@import '../../styles/base/base.scss';

.reg {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 4rem;
        color: $dark-green;
    }
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    // height: 60vh;

    .heading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;

        h1 {
            font-size: 4rem;
            color: $dark-green;
            margin: 0px 3rem;

            @include respond(sm) {
                margin: 0px;
            }
        }

        img {
            max-width: 65px;
            
            @include respond(md) {
                max-width: 45px;
            }

            @include respond(sm) {
                display: none;
            }

        }
    
        .left {
            transform: rotate(-90deg);
        }
    
        .right {
            transform: rotate(90deg);
        }
    }
    
    .rules {
        background-color: $lighter-green;
        color: $dark-green;
        font-size: 2rem;
        padding: 1rem;
        text-align: center;
        // border: solid $dark-green;
        border-radius: 0.8rem;
        margin: 0.5rem;

        a {
            font-weight: 700;
            color: $orange;
            // text-decoration: none;
        }
    }

    .innerContainer {
        width: 100%;
        max-width: 900px;
        padding: 5rem;
        
        @include respond(sm) {
            padding: 0px 0px 3rem 0px;
        }


        

        label {
            // width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 2rem;
            padding: 5px;

            @include respond(sm) {
                padding: 2px;
            }

            input {
                border: solid 3px $dark-green;
                border-radius: 10px;
                height: 4.5rem;
                padding: 5px;
                padding-left: 10px;
                
                &::placeholder {
                    padding-left: 10px;
                }
            }
        }

        .submit {
            // padding: 5px;
            margin: 5px;
            padding: 1rem 0rem;
            
            :hover {
                cursor: pointer;
            }

            input {
                height: 5rem;
                border-radius: 10px;
                border: none;
                background-color: $orange;
                width: 100%;
                font-weight: 2rem;
                font-size: 2rem;
                color: $background;
            }
        }
    }
    .loaderBG{
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: pink;
        min-width: 100%;
        min-height: 100vh;


        .loader{
            
            height: 100px;
            width: 100px;
        }
    }
    
    .popup {
        // display: none; /* Hidden by default */
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100vh; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        // background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        
        @include respond(xs) {
            padding: 2rem;
            
        }

        .content {
            position: relative;
            background-color: $light-green;
            border: solid $dark-green 5px;
            border-radius: 20px;
            color: $dark-green;
            padding: 20px;
            font-size: 2.5rem;
            font-weight: 100;

            max-width: 40rem;
            max-height: 50rem;
            width: 100%;
            height: 100%;
            // width: 80%; /* Could be more or less, depending on screen size */

            .message {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: column;
                height: 100%;

                h1, p {
                    text-align: center;
                }

                h1 {
                    font-weight: 300;
                }

                p {
                    font-size: 1.2rem;
                }

                .errMsg {
                    font-size: 1.6rem;
                    font-weight: 800;
                }    
            
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    color: $background;
                    text-align: center;
                    background-color: $orange;
                    text-decoration: none;
                    padding: 1rem 2rem;
                    // min-width: 100px;
                    height: 50px;
                    transition: all 0.5s ease 0s;
                    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 3px 7px);
                    filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 3px 7px);
                }

                a:hover {
                //     -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 3px 7px);
                //     filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 3px 7px);
                    // scale: 1.1;
                    transform: scale(1.08);
                }

                .resgistrationID{
                    font-weight: 800;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin: 0 auto;
                    justify-content: center;

                    img{
                        width: 15px;
                        margin-left: 5px;
                    }

                    @include respond(xs) {
                        font-size: 1rem;
                        // width: 15px;

                        img {
                            width: 15px;
                        }
                    }
                }
            }
            
            img {
                width: 10rem;
            }

            .flower {
                width: 7rem;
                margin-top: -8rem;
                // z-index: 100;
            }

            .checked {
                -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 3px 7px);
                filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 3px 7px);
                // drop-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            }

            .close {
                margin-top: -10px;
                color: $background;
                float: right;
                font-size: 3rem;
                font-weight: bold;
                position: absolute;
                top: 20px;
                right: 20px;
            }
              
            .close:hover,
            .close:focus {
                color: $dark-green;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}

