@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/variables.scss';
@import '../../styles/base/base.scss';
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

.heroSection {
  height: 85vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 100px;

  .svg {
    z-index: -1;

    .left {
      max-width: 100%;
      transform: rotate(180deg);
    }

    .right {
      max-width: 100%;
    }

    @include respond(md) {
      // or maybe do md
      display: none;
      // width: 1rem;
      // img {
      //   width: 90%;
      //   // object-fit: contain;
      // }
    }
  }

  .heading {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // .headingText {
    //   font-size: 8rem;
    //   font-weight: 900;
    //   color: $orange;
    // }

    img {
      width: 100%;
      height: auto;
      max-width: 550px;
      flex-shrink: 2;
    }

    .logo {
      padding: 0px 100px;
      @include respond(sm) {
        // or maybe do md
        padding: 0px 50px;
      }
    }

    h1 {
      margin-top: 20px;
      color: $orange;
      font-family: 'Yellowtail', cursive;
      font-size: 5rem;
      text-align: center;
      font-weight: 400;
    }

    h2 {
      margin-top: -5px;
      font-size: 2.3rem;
      color: $green;
    }

    .register {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 60px;

        @include respond(xs) {
          // or maybe do md
          display: none;
        }
      }

      // the left and right flowerss
      .left {
        transform: rotate(-90deg);
      }

      .right {
        transform: rotate(90deg);
      }
    }

    a {
      margin: 20px;
      width: 200px;
      height: 50px;
      background-color: $orange;
      // padding: 10px 50px;
      padding: 5px 25px;
      color: $background;
      font-size: 2rem;
      font-weight: 600;
      border-radius: 50px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s;

      @include respond(sm) {
        // or maybe do md
        width: 150px;
        height: 40px;
        // background-color: aquamarine;
      }
    }
  }
}
