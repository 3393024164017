@import '../../styles/abstract/mixins.scss';
@import '../../styles/abstract/variables.scss';
@import '../../styles/base/base.scss';

.container {
    // background-color: #B8CDB4;
    background-color: $light-green;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    margin-top: 5rem;
    // margin: 5rem 0rem;
    padding: 5rem 0rem;

    .main {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
            font-size: 4rem;
            color: $dark-green;
        }

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 2rem;

            .left, .right {
                width: 8rem;

                @include respond (lg) {
                    display: none;
                    // background-color: black;
                }
            }

            .left {
                transform: scaleX(-1);
            }
            
            .right {
                transform: scaleY(-1);
            }
            
            .tracks{
                display: flex;
                max-width: 1200px;
                // flex-direction: row;
                // max-width: 600px;
                // min-height: 600px;
                justify-content: center;
                align-items: stretch;
                flex-wrap: wrap;
                
                @include respond(sm) {
                    // width: 100%;
                    // height: 100%;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            
                .track{
                    flex-basis: 45%;
                    // width: 550px;
                    // height: 200px;
                    background-color: $background;
                    color: $dark-green;
                    margin: 1rem;
                    padding: 3rem;
                    border-radius: 0.8rem;
                    box-shadow: rgba(43, 53, 49, 0.35) 0px 5px 15px;
                    
                    // padding:20px;
                    // border-radius:10px;
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    
                    @include respond (lg) {
                        width: 100%;
                    }

                    @include respond (md) {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    // @include respond (sm) {
                    //     flex-direction: column;
                    //     justify-content: center;
                    //     align-items: center;
                    // }

                    // img {
                    //     width: 9rem;

                    //     @include respond (lg) {
                    //         display: none;
                    //     }
                    // }

                    div {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        // justify-content: center;
                        // margin-left: 3rem;
                    
                        @include respond (sm) {
                            margin-left: 0rem;
                        }

                        h2 {
                            font-size: 2rem;
                            padding-bottom: 1rem;
                        
                            @include respond (sm) {
                                text-align: center;
                            }
                        }

                        p {
                            font-size: 1.5rem;
                        }
                    }
                }
            }       
        }
    }
}